<template>
  <div class="content-booking-rental">
    <div v-if="fullLoad" id="loading">
      <p id="loading-image" class="text-center mt-5" style="text-align: center">
        <svg
          width="40"
          height="40"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="var(--primary)"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </p>
    </div>
  
    <div style="align-items:center;justify-content:center;display:flex; height:50px; width:100%;">
      <div style="background-color:var(--primary); width:3px; height:3px;">


      </div>
    </div>
    
    
  </div>
</template>

<style scoped>
  body {
    display: none !important;
  }
  #loading {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.6;
    background-color: rgb(0, 0, 0);
    z-index: 99;
  }

  #loading-image {
    position: absolute;
    top: 30%;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 100;
  }
</style>

<script>
import Moment from 'moment';
import templateCustom from './template';

export default {
  props: ['bikesTags', 'paramsRequest', 'optionsPerDropdownButton', 'paramsFilterRequestPOS', 'tagToFilterRoom', 'allowSetPOSSelectInTerms'],

  data() {
    return {
      events:[],
      fullLoad:true,
      optionsPerDropdown:this.optionsPerDropdownButton || 8
    };
  },

  created(){
    

  },
  mounted(){
    let interval = setInterval(()=>{
      if(!this.paramsFilterRequestPOS.length){
        console.log('aki');
        return; 
      }
      clearInterval(interval);
      let el = document.createElement('ae-event-list');
      if(window.location.hostname != 'localhost') el.style = 'display:none;';

      this.compileTemplate();

      el.template = 'diferentao';
      el.params = { ...{date_from: Moment().format('YYYY-MM-DD')}, ...this.paramsRequest};
      el.loadAll = true;
      el.paramsFilterRequestPOS = this.paramsFilterRequestPOS;
      el.tagToFilterRoom = this.tagToFilterRoom;
      el.allowSetPOSSelectInTerms = this.allowSetPOSSelectInTerms

      el.addEventListener('toLogin', ()=>{
        this.$emit('toLogin');
        document.querySelector('ae-login-modal').show = true;
      });

      el.addEventListener('toCheckout', ()=>{
        this.$emit('toCheckout');
        document.querySelector('ae-checkout-modal').open = true;
      });


      document.querySelector('.content-booking-rental').insertBefore(el, null);

      el.addEventListener('allDataLoaded', ()=>{
        let interval1 = setInterval(()=>{
          if(document.querySelectorAll('.event-action-btn').length){

            clearInterval(interval1);

            let result = [];
            document.querySelectorAll('.event-action-btn').forEach((item, i)=>{
                result.push(i+'|'+item.textContent);
            });

          this.events = result.map(item=>item.split('|'));

          //Testes
          //this.events.push(["0","2021-07-08T15:40:00-03:00", "2021-07-08T17:30:00-03:00", "16:32", "08/06/2021", "Grom 12", "grom", "grom12-aluminio", "Cancelar"]);
          //this.events.push(["0","2021-07-09T15:40:00-03:00", "2021-07-09T17:30:00-03:00", "15:40", "09/06/2021", "Grom 12", "grom", "grom12-aluminio", "Cancelar"]);
          //this.events.push(["0","2021-07-08T12:40:00-03:00", "2021-07-08T17:30:00-03:00", "12:40", "08/06/2021", "Grom 12", "grom", "grom12-aluminio", "Cancelar"]);
          

          //verifica se existe algum evento que já terminou. se existir, ele retira da lista
          this.events = this.withdrawFinishedEvent(this.events);
          
          //faz o loop nas tags passadas e popula os botoes
          this.bikesTags.forEach(item=>{
            this.populate(item);
          });
              
          this.fullLoad = false;
          }
        });
      }); 
    },200);
    

    
    
  },
  methods: {
    withdrawFinishedEvent(events){
      events.forEach((item, i)=>{
        if(Moment(item[2]) < Moment()){
          delete events[i];
        }
      });
      return events;
    },
    getByTag2(tag){
      return this.events.filter(item=>item[7]==tag);
    },
    book(id){
      //console.log(id)
      document.querySelectorAll('.event-action-btn')[id].click();
    },
    populate(name){
      let list = this.getByTag2(name);
      
       //orderna por data a lista de eventos já filtradas por tag
       list = list.sort(function compare(a, b) {
          var dateA = new Date(a[1]);
          var dateB = new Date(b[1]);
          return dateA - dateB;
        }); 

       if(!document.querySelector('.'+name+'-reservar-opcoes')) return;


       let dropdownGrom = document.querySelector('.'+name+'-reservar-opcoes').parentNode.querySelector('.dropdown-menu');

       list.forEach(item=>{
        if(item[item.length-1] != 'Reservar'){
          if(item[item.length-1] == 'Cancelar'){
            window.aaa = document.createElement('a');
            if(Moment().format('YYYY-MM-DD') == Moment(item[1]).format('YYYY-MM-DD')){
              if(Moment(item[1]) < Moment()){
                window.aaa .textContent = 'Locar agora (Indisponível)';
              }
              if(Moment(item[1]) > Moment()){
                window.aaa .textContent = 'Reservar para hoje '+item[3]+' (Indisponível)';
              }
            }else if(Moment().add({days: 1}).format('YYYY-MM-DD') == Moment(item[1]).format('YYYY-MM-DD')){
              window.aaa .textContent = 'Reservar para amanhã '+item[3]+' (Indisponível)';
            }else{
              window.aaa .textContent = 'Indisponível em '+item[4]+' '+item[3];
            }
            window.aaa .className = 'dropdown-item disabled';
            window.aaa .style = 'color:#bbb !important;cursor:not-allowed !important; pointer-events: all !important;';
            //window.aaa .onclick = ()=>this.book(item[0]);
            if (dropdownGrom.childElementCount >= this.optionsPerDropdown) return; 
            dropdownGrom.insertBefore(window.aaa, null);
          }
          return;
        }
        if(Moment().format('YYYY-MM-DD') == Moment(item[1]).format('YYYY-MM-DD')){
          window.aaa = document.createElement('a');
          if(Moment(item[1]) < Moment()){
            window.aaa .textContent = 'Locar agora';
          }
          if(Moment(item[1]) > Moment()){
            window.aaa .textContent = 'Reservar para hoje '+item[3];
          }
          window.aaa .className = 'dropdown-item';
          window.aaa .style = 'cursor:pointer;';
          window.aaa .onclick = ()=>this.book(item[0]);

          dropdownGrom.insertBefore(window.aaa, null);
        }else if(Moment().add({days: 1}).format('YYYY-MM-DD') == Moment(item[1]).format('YYYY-MM-DD')){
          window.aaa = document.createElement('a');
          window.aaa .textContent = 'Reservar para amanhã '+item[3];
          window.aaa .className = 'dropdown-item';
          window.aaa .style = 'cursor:pointer;';
          window.aaa .onclick = ()=>this.book(item[0]);

          dropdownGrom.insertBefore(window.aaa, null);
        }else{
          window.aaa = document.createElement('a');
          window.aaa .textContent = 'Reservar para '+item[4]+' '+item[3];
          window.aaa .className = 'dropdown-item';
          window.aaa .style = 'cursor:pointer;';
          window.aaa .onclick = ()=>this.book(item[0]);
          if (dropdownGrom.childElementCount >= this.optionsPerDropdown) return; 

          dropdownGrom.insertBefore(window.aaa, null);
        }
       });

       let existDisabled = false;
       document.querySelector('.'+name+'-reservar-opcoes').classList.forEach(item=>{
         if(item=='disabled') existDisabled = true;
       });


       if(document.querySelector('.'+name+'-reservar-opcoes').parentNode.querySelector('.dropdown-menu').children.length==0){
         if(!existDisabled) document.querySelector('.'+name+'-reservar-opcoes').classList.add('disabled');
       }
       else{
         document.querySelector('.'+name+'-reservar-opcoes').innerHTML = '<i class="far fa-calendar-alt"></i> Reservar ';
         if(existDisabled) {
           document.querySelector('.'+name+'-reservar-opcoes').classList.remove('disabled');
          }
       }

  
       
    },
    compileTemplate(){
      const compiler = require('vue-template-compiler');

      let template = templateCustom.template.split('<br>').join('');

      const compiledComponent = compiler.compileToFunctions(template);

      window.AngularEcommerce = window.AngularEcommerce || {};
      window.AngularEcommerce.web = window.AngularEcommerce.web || {};
      window.AngularEcommerce.web.templates = {
          events: {
              diferentao: compiledComponent
          }
      };
    }
  }

};
</script>