var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-booking-rental" }, [
    _vm.fullLoad
      ? _c("div", { attrs: { id: "loading" } }, [
          _c(
            "p",
            {
              staticClass: "text-center mt-5",
              staticStyle: { "text-align": "center" },
              attrs: { id: "loading-image" }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "40",
                    height: "40",
                    viewBox: "0 0 38 38",
                    xmlns: "http://www.w3.org/2000/svg",
                    stroke: "var(--primary)"
                  }
                },
                [
                  _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "translate(1 1)",
                          "stroke-width": "2"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            "stroke-opacity": ".5",
                            cx: "18",
                            cy: "18",
                            r: "18"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "path",
                          { attrs: { d: "M36 18c0-9.94-8.06-18-18-18" } },
                          [
                            _c("animateTransform", {
                              attrs: {
                                attributeName: "transform",
                                type: "rotate",
                                from: "0 18 18",
                                to: "360 18 18",
                                dur: "1s",
                                repeatCount: "indefinite"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "align-items": "center",
          "justify-content": "center",
          display: "flex",
          height: "50px",
          width: "100%"
        }
      },
      [
        _c("div", {
          staticStyle: {
            "background-color": "var(--primary)",
            width: "3px",
            height: "3px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }